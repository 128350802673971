import axios from "axios";

export default {
  update(id, data) {
    return axios.post(`/api/pages/${id}`, data)
  },
  store(data) {
    return axios.post(`/api/pages`, data)
  },
  delete(id) {
    return axios.delete(`/api/pages/${id}`)
  }
}
