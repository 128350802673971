<template>
  <v-app>
    <v-container fluid>
      <v-data-table
        :headers="headers"
        :items="pages"
        :page.sync="page"
        :server-items-length="meta.total"
        :items-per-page="meta.per_page"
        :loading="loading"
        hide-default-footer
        class="elevation-1"
      >
        <template v-slot:top>
          <state-indicate ref="indicate" v-slot:default="{isLoading}">
            <v-toolbar
              flat
            >
              <v-toolbar-title>Pages List</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-spacer></v-spacer>
              <v-dialog
                v-model="dialog"
                max-width="800px"
              >
                <template v-slot:activator="{ on, attrs }">
<!--                  <v-btn-->
<!--                    @click="editedIndex = -1"-->
<!--                    color="primary"-->
<!--                    dark-->
<!--                    class="mb-2"-->
<!--                    v-bind="attrs"-->
<!--                    v-on="on"-->
<!--                  >-->
<!--                    New Page-->
<!--                  </v-btn>-->
                </template>
                <ValidationObserver ref="observer" v-slot="{ invalid }">
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col
                            cols="12"
                          >
                            <ValidationProvider name="Title" vid="title" rules="required" v-slot="{ errors, valid }">
                              <v-text-field
                                :counter="255"
                                v-model="editedItem.title"
                                :error-messages="errors"
                                :success="valid"
                                label="Title"
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col
                            cols="12"
                            v-if="editedIndex !== -1"
                          >
                            <ValidationProvider name="Slug" vid="slug" rules="required" v-slot="{ errors, valid }">
                              <v-text-field
                                :counter="255"
                                v-model="editedItem.slug"
                                :error-messages="errors"
                                :success="valid"
                                label="Slug"
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col
                            cols="12"
                          >
                            <ckeditor
                              :editor="editor"
                              v-model="editedItem.body"
                              :config="editorConfig"
                            ></ckeditor>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="close"
                        :disabled="isLoading"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="save"
                        :disabled="invalid"
                        :loading="isLoading"
                      >
                        {{ editedIndex > -1 ? 'Save' : 'Create'}}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </ValidationObserver>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" :disabled="isLoading" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" :loading="isLoading" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </state-indicate>
        </template>
        <template v-slot:item.image="{ item }">
          <v-img :src="item.image_url" width="200" />
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
<!--          <v-icon-->
<!--            small-->
<!--            @click="deleteItem(item)"-->
<!--          >-->
<!--            mdi-delete-->
<!--          </v-icon>-->
        </template>
        <template v-slot:no-data>
          <v-btn
            color="primary"
          >
            Reset
          </v-btn>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination
          v-model="page"
          :length="meta.last_page"
        ></v-pagination>
      </div>
    </v-container>
  </v-app>
</template>

<script>
    import PagesApi from '@/api/pages';
    import StateIndicate from "../../../components/utils/StateIndicate";
    import Page from "@/models/Page";
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

    export default {
        name: "Index",
        data() {
            return {
                editor: ClassicEditor,
                editorConfig: {
                    // The configuration of the editor.
                },
                loading: false,
                pages: [],
                dialog: false,
                dialogDelete: false,
                editedIndex: -1,
                editedItem: {
                    id: '',
                    title: '',
                    body: '',
                    slug: ''
                },
                defaultItem: {
                    id: '',
                    title: '',
                    body: '',
                    slug: ''
                },
                headers: [
                    {text: 'Title', value: 'title'},
                    {text: 'Slug', value: 'slug'},
                    {text: 'Body', value: 'body'},
                    {text: 'Created at', value: 'created_at'},
                    {text: 'Actions', value: 'actions', sortable: false},
                ],
                meta: {},
                page: 1,
            }
        },

        watch: {
            async page() {
                this.fetchPages()
            },
            dialog(val) {
                if (val && this.editedIndex === -1) {
                    this.editedItem = Object.assign({}, this.defaultItem)
                }

                if (!val) {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1;
                    this.$nextTick(() => {
                        this.$refs.observer.reset()
                    });

                }
            }
        },

        computed: {
            formTitle() {
                return this.editedIndex === -1 ? 'New Page' : 'Edit Page'
            },
        },
        async mounted() {
            this.fetchPages()
        },

        methods: {
            fetchPages() {
                this.loading = true;
                return Page.page(this.page).get().then(ApiResponse => {
                    this.pages = ApiResponse.getData();
                    this.meta = ApiResponse.getMeta();
                    this.loading = false;
                });
            },
            editItem(item) {
                this.editedIndex = this.pages.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialog = true
            },

            deleteItem(item) {
                this.editedIndex = this.pages.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialogDelete = true
            },

            async deleteItemConfirm() {
                this.$refs.indicate.isLoading = true
                try {
                    await PagesApi.delete(this.editedItem.id)
                    this.closeDelete()
                    await this.fetchPages()
                } catch (e) {}
                this.$refs.indicate.isLoading = false
            },

            close() {
                this.dialog = false
            },

            closeDelete() {
                this.dialogDelete = false
                this.$nextTick(() => {
                    Object.assign(this.editedItem, this.defaultItem)
                    this.editedIndex = -1
                })
            },

            async save() {
                if (await this.$refs.observer.validate()) {
                    this.$refs.indicate.isLoading = true;
                    let formData = await this.getFormData()
                    if (this.editedIndex > -1) {
                        try {
                            await PagesApi.update(this.editedItem.id, formData);
                            Object.assign(this.pages[this.editedIndex], this.editedItem);
                            this.close();
                        } catch (ApiResponse) {
                            if (ApiResponse.hasValidationErrors()) {
                                this.$refs.observer.setErrors(ApiResponse.getValidationErrors());
                            } else {
                                alert('Error while for updating Page');
                            }
                        }
                    } else {
                        try {
                            await PagesApi.store(formData);
                            this.close();
                            await this.fetchPages()
                        } catch (ApiResponse) {
                            if (ApiResponse.hasValidationErrors()) {
                                this.$refs.observer.setErrors(ApiResponse.getValidationErrors());
                            } else {
                                alert('Error while for creating Page');
                            }
                        }
                    }
                    this.$refs.indicate.isLoading = false;
                }
            },
            async getFormData() {
                let formData = new FormData();

                Object.keys(this.editedItem).forEach(key => {
                    formData.append(key, this.editedItem[key])
                });

                if (this.editedIndex > -1) {
                    formData.append('_method', 'PUT')
                }

                return formData;
            }
        },

        components: {
            StateIndicate
        }
    }
</script>

<style>
  .ck-editor__editable {
    min-height: 200px;
  }
</style>
